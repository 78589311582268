define("@ember-data/private-build-infra/deprecations", ["exports", "@ember-data/private-build-infra/current-deprecations"], function (_exports, _currentDeprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEPRECATE_V1_RECORD_DATA = _exports.DEPRECATE_V1CACHE_STORE_APIS = _exports.DEPRECATE_STRING_ARG_SCHEMAS = _exports.DEPRECATE_STORE_FIND = _exports.DEPRECATE_SNAPSHOT_MODEL_CLASS_ACCESS = _exports.DEPRECATE_SAVE_PROMISE_ACCESS = _exports.DEPRECATE_RSVP_PROMISE = _exports.DEPRECATE_RELATIONSHIPS_WITHOUT_TYPE = _exports.DEPRECATE_RELATIONSHIPS_WITHOUT_INVERSE = _exports.DEPRECATE_RELATIONSHIPS_WITHOUT_ASYNC = _exports.DEPRECATE_PROMISE_PROXIES = _exports.DEPRECATE_PROMISE_MANY_ARRAY_BEHAVIORS = _exports.DEPRECATE_NON_EXPLICIT_POLYMORPHISM = _exports.DEPRECATE_MODEL_REOPEN = _exports.DEPRECATE_JSON_API_FALLBACK = _exports.DEPRECATE_HELPERS = _exports.DEPRECATE_HAS_RECORD = _exports.DEPRECATE_EARLY_STATIC = _exports.DEPRECATE_COMPUTED_CHAINS = _exports.DEPRECATE_CLASSIC = _exports.DEPRECATE_CATCH_ALL = _exports.DEPRECATE_A_USAGE = _exports.DEPRECATE_ARRAY_LIKE = _exports.DEPRECATE_3_12 = void 0;
  function deprecationState(deprecationName) {
    // if we hit this at runtime and the deprecation exists it is always activated
    return deprecationName in _currentDeprecations.default;
  }

  // deprecations
  var DEPRECATE_CATCH_ALL = deprecationState('DEPRECATE_CATCH_ALL');
  _exports.DEPRECATE_CATCH_ALL = DEPRECATE_CATCH_ALL;
  var DEPRECATE_3_12 = deprecationState('DEPRECATE_3_12');
  _exports.DEPRECATE_3_12 = DEPRECATE_3_12;
  var DEPRECATE_SAVE_PROMISE_ACCESS = deprecationState('DEPRECATE_SAVE_PROMISE_ACCESS');
  _exports.DEPRECATE_SAVE_PROMISE_ACCESS = DEPRECATE_SAVE_PROMISE_ACCESS;
  var DEPRECATE_RSVP_PROMISE = deprecationState('DEPRECATE_RSVP_PROMISE');
  _exports.DEPRECATE_RSVP_PROMISE = DEPRECATE_RSVP_PROMISE;
  var DEPRECATE_SNAPSHOT_MODEL_CLASS_ACCESS = deprecationState('DEPRECATE_SNAPSHOT_MODEL_CLASS_ACCESS');
  _exports.DEPRECATE_SNAPSHOT_MODEL_CLASS_ACCESS = DEPRECATE_SNAPSHOT_MODEL_CLASS_ACCESS;
  var DEPRECATE_STORE_FIND = deprecationState('DEPRECATE_STORE_FIND');
  _exports.DEPRECATE_STORE_FIND = DEPRECATE_STORE_FIND;
  var DEPRECATE_HAS_RECORD = deprecationState('DEPRECATE_HAS_RECORD');
  _exports.DEPRECATE_HAS_RECORD = DEPRECATE_HAS_RECORD;
  var DEPRECATE_STRING_ARG_SCHEMAS = deprecationState('DEPRECATE_STRING_ARG_SCHEMAS');
  _exports.DEPRECATE_STRING_ARG_SCHEMAS = DEPRECATE_STRING_ARG_SCHEMAS;
  var DEPRECATE_JSON_API_FALLBACK = deprecationState('DEPRECATE_JSON_API_FALLBACK');
  _exports.DEPRECATE_JSON_API_FALLBACK = DEPRECATE_JSON_API_FALLBACK;
  var DEPRECATE_MODEL_REOPEN = deprecationState('DEPRECATE_MODEL_REOPEN');
  _exports.DEPRECATE_MODEL_REOPEN = DEPRECATE_MODEL_REOPEN;
  var DEPRECATE_EARLY_STATIC = deprecationState('DEPRECATE_EARLY_STATIC');
  _exports.DEPRECATE_EARLY_STATIC = DEPRECATE_EARLY_STATIC;
  var DEPRECATE_CLASSIC = deprecationState('DEPRECATE_CLASSIC');
  _exports.DEPRECATE_CLASSIC = DEPRECATE_CLASSIC;
  var DEPRECATE_HELPERS = deprecationState('DEPRECATE_HELPERS');
  _exports.DEPRECATE_HELPERS = DEPRECATE_HELPERS;
  var DEPRECATE_PROMISE_MANY_ARRAY_BEHAVIORS = deprecationState('DEPRECATE_PROMISE_MANY_ARRAY_BEHAVIORS');
  _exports.DEPRECATE_PROMISE_MANY_ARRAY_BEHAVIORS = DEPRECATE_PROMISE_MANY_ARRAY_BEHAVIORS;
  var DEPRECATE_V1CACHE_STORE_APIS = deprecationState('DEPRECATE_V1CACHE_STORE_APIS');
  _exports.DEPRECATE_V1CACHE_STORE_APIS = DEPRECATE_V1CACHE_STORE_APIS;
  var DEPRECATE_RELATIONSHIPS_WITHOUT_TYPE = deprecationState('DEPRECATE_RELATIONSHIPS_WITHOUT_TYPE');
  _exports.DEPRECATE_RELATIONSHIPS_WITHOUT_TYPE = DEPRECATE_RELATIONSHIPS_WITHOUT_TYPE;
  var DEPRECATE_RELATIONSHIPS_WITHOUT_ASYNC = deprecationState('DEPRECATE_RELATIONSHIPS_WITHOUT_ASYNC');
  _exports.DEPRECATE_RELATIONSHIPS_WITHOUT_ASYNC = DEPRECATE_RELATIONSHIPS_WITHOUT_ASYNC;
  var DEPRECATE_RELATIONSHIPS_WITHOUT_INVERSE = deprecationState('DEPRECATE_RELATIONSHIPS_WITHOUT_INVERSE');
  _exports.DEPRECATE_RELATIONSHIPS_WITHOUT_INVERSE = DEPRECATE_RELATIONSHIPS_WITHOUT_INVERSE;
  var DEPRECATE_V1_RECORD_DATA = deprecationState('DEPRECATE_V1_RECORD_DATA');
  _exports.DEPRECATE_V1_RECORD_DATA = DEPRECATE_V1_RECORD_DATA;
  var DEPRECATE_A_USAGE = deprecationState('DEPRECATE_A_USAGE');
  _exports.DEPRECATE_A_USAGE = DEPRECATE_A_USAGE;
  var DEPRECATE_PROMISE_PROXIES = deprecationState('DEPRECATE_PROMISE_PROXIES');
  _exports.DEPRECATE_PROMISE_PROXIES = DEPRECATE_PROMISE_PROXIES;
  var DEPRECATE_ARRAY_LIKE = deprecationState('DEPRECATE_ARRAY_LIKE');
  _exports.DEPRECATE_ARRAY_LIKE = DEPRECATE_ARRAY_LIKE;
  var DEPRECATE_COMPUTED_CHAINS = deprecationState('DEPRECATE_COMPUTED_CHAINS');
  _exports.DEPRECATE_COMPUTED_CHAINS = DEPRECATE_COMPUTED_CHAINS;
  var DEPRECATE_NON_EXPLICIT_POLYMORPHISM = deprecationState('DEPRECATE_NON_EXPLICIT_POLYMORPHISM');
  _exports.DEPRECATE_NON_EXPLICIT_POLYMORPHISM = DEPRECATE_NON_EXPLICIT_POLYMORPHISM;
});