define("ember-composable-helpers/index", ["exports", "ember-composable-helpers/helpers/filter-by", "ember-composable-helpers/helpers/slice"], function (_exports, _filterBy, _slice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "FilterByHelper", {
    enumerable: true,
    get: function get() {
      return _filterBy.default;
    }
  });
  Object.defineProperty(_exports, "SliceHelper", {
    enumerable: true,
    get: function get() {
      return _slice.default;
    }
  });
});