define("@ember/object/lib/computed/reduce_computed_macros", ["exports", "ember-babel", "@ember/debug", "@ember/-internals/metal", "@ember/-internals/runtime", "@ember/-internals/runtime/lib/mixins/array"], function (_exports, _emberBabel, _debug, _metal, _runtime, _array2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.collect = collect;
  _exports.filter = filter;
  _exports.filterBy = filterBy;
  _exports.intersect = intersect;
  _exports.map = map;
  _exports.mapBy = mapBy;
  _exports.max = max;
  _exports.min = min;
  _exports.setDiff = setDiff;
  _exports.sort = sort;
  _exports.sum = sum;
  _exports.union = void 0;
  _exports.uniq = uniq;
  _exports.uniqBy = uniqBy;
  /**
  @module @ember/object
  */

  function isNativeOrEmberArray(obj) {
    return Array.isArray(obj) || _array2.default.detect(obj);
  }
  function reduceMacro(dependentKey, callback, initialValue, name) {
    (true && !(!/[[\]{}]/g.test(dependentKey)) && (0, _debug.assert)("Dependent key passed to `" + name + "` computed macro shouldn't contain brace expanding pattern.", !/[[\]{}]/g.test(dependentKey)));
    return (0, _metal.computed)(dependentKey + ".[]", function () {
      var arr = (0, _metal.get)(this, dependentKey);
      if (arr === null || typeof arr !== 'object') {
        return initialValue;
      }
      return arr.reduce(callback, initialValue, this);
    }).readOnly();
  }
  function arrayMacro(dependentKey, additionalDependentKeys, callback) {
    // This is a bit ugly
    var propertyName;
    if (/@each/.test(dependentKey)) {
      propertyName = dependentKey.replace(/\.@each.*$/, '');
    } else {
      propertyName = dependentKey;
      dependentKey += '.[]';
    }
    return _metal.computed.apply(void 0, [dependentKey].concat(additionalDependentKeys, [function () {
      var value = (0, _metal.get)(this, propertyName);
      if (isNativeOrEmberArray(value)) {
        return (0, _runtime.A)(callback.call(this, value));
      } else {
        return (0, _runtime.A)();
      }
    }])).readOnly();
  }
  function multiArrayMacro(_dependentKeys, callback, name) {
    (true && !(_dependentKeys.every(function (dependentKey) {
      return !/[[\]{}]/g.test(dependentKey);
    })) && (0, _debug.assert)("Dependent keys passed to `" + name + "` computed macro shouldn't contain brace expanding pattern.", _dependentKeys.every(function (dependentKey) {
      return !/[[\]{}]/g.test(dependentKey);
    })));
    var dependentKeys = _dependentKeys.map(function (key) {
      return key + ".[]";
    });
    return _metal.computed.apply(void 0, dependentKeys.concat([function () {
      return (0, _runtime.A)(callback.call(this, _dependentKeys));
    }])).readOnly();
  }
  /**
    A computed property that returns the sum of the values in the dependent array.
  
    Example:
  
    ```javascript
    import { sum } from '@ember/object/computed';
  
    class Invoice {
      lineItems = [1.00, 2.50, 9.99];
  
      @sum('lineItems') total;
    }
  
    let invoice = new Invoice();
  
    invoice.total; // 13.49
    ```
  
    @method sum
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @return {ComputedProperty} computes the sum of all values in the
    dependentKey's array
    @since 1.4.0
    @public
  */

  function sum(dependentKey) {
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @sum as a decorator directly, but it requires a `dependentKey` parameter', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    return reduceMacro(dependentKey, function (sum, item) {
      return sum + item;
    }, 0, 'sum');
  }
  /**
    A computed property that calculates the maximum value in the dependent array.
    This will return `-Infinity` when the dependent array is empty.
  
    Example:
  
    ```javascript
    import { set } from '@ember/object';
    import { mapBy, max } from '@ember/object/computed';
  
    class Person {
      children = [];
  
      @mapBy('children', 'age') childAges;
      @max('childAges') maxChildAge;
    }
  
    let lordByron = new Person();
  
    lordByron.maxChildAge; // -Infinity
  
    set(lordByron, 'children', [
      {
        name: 'Augusta Ada Byron',
        age: 7
      }
    ]);
    lordByron.maxChildAge; // 7
  
    set(lordByron, 'children', [
      ...lordByron.children,
      {
        name: 'Allegra Byron',
        age: 5
      }, {
        name: 'Elizabeth Medora Leigh',
        age: 8
      }
    ]);
    lordByron.maxChildAge; // 8
    ```
  
    If the types of the arguments are not numbers, they will be converted to
    numbers and the type of the return value will always be `Number`. For example,
    the max of a list of Date objects will be the highest timestamp as a `Number`.
    This behavior is consistent with `Math.max`.
  
    @method max
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @return {ComputedProperty} computes the largest value in the dependentKey's
    array
    @public
  */

  function max(dependentKey) {
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @max as a decorator directly, but it requires a `dependentKey` parameter', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    return reduceMacro(dependentKey, function (max, item) {
      return Math.max(max, item);
    }, -Infinity, 'max');
  }
  /**
    A computed property that calculates the minimum value in the dependent array.
    This will return `Infinity` when the dependent array is empty.
  
    Example:
  
    ```javascript
    import { set } from '@ember/object';
    import { mapBy, min } from '@ember/object/computed';
  
    class Person {
      children = [];
  
      @mapBy('children', 'age') childAges;
      @min('childAges') minChildAge;
    }
  
    let lordByron = Person.create({ children: [] });
  
    lordByron.minChildAge; // Infinity
  
    set(lordByron, 'children', [
      {
        name: 'Augusta Ada Byron',
        age: 7
      }
    ]);
    lordByron.minChildAge; // 7
  
    set(lordByron, 'children', [
      ...lordByron.children,
      {
        name: 'Allegra Byron',
        age: 5
      }, {
        name: 'Elizabeth Medora Leigh',
        age: 8
      }
    ]);
    lordByron.minChildAge; // 5
    ```
  
    If the types of the arguments are not numbers, they will be converted to
    numbers and the type of the return value will always be `Number`. For example,
    the min of a list of Date objects will be the lowest timestamp as a `Number`.
    This behavior is consistent with `Math.min`.
  
    @method min
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @return {ComputedProperty} computes the smallest value in the dependentKey's array
    @public
  */

  function min(dependentKey) {
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @min as a decorator directly, but it requires a `dependentKey` parameter', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    return reduceMacro(dependentKey, function (min, item) {
      return Math.min(min, item);
    }, Infinity, 'min');
  }
  function map(dependentKey, additionalDependentKeysOrCallback, callback) {
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @map as a decorator directly, but it requires atleast `dependentKey` and `callback` parameters', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    (true && !(typeof callback === 'function' || callback === undefined && typeof additionalDependentKeysOrCallback === 'function') && (0, _debug.assert)('The final parameter provided to map must be a callback function', typeof callback === 'function' || callback === undefined && typeof additionalDependentKeysOrCallback === 'function'));
    (true && !(Array.isArray(additionalDependentKeysOrCallback) || typeof additionalDependentKeysOrCallback === 'function') && (0, _debug.assert)('The second parameter provided to map must either be the callback or an array of additional dependent keys', Array.isArray(additionalDependentKeysOrCallback) || typeof additionalDependentKeysOrCallback === 'function'));
    var additionalDependentKeys;
    if (typeof additionalDependentKeysOrCallback === 'function') {
      callback = additionalDependentKeysOrCallback;
      additionalDependentKeys = [];
    } else {
      additionalDependentKeys = additionalDependentKeysOrCallback;
    }
    var cCallback = callback;
    (true && !(cCallback) && (0, _debug.assert)('[BUG] Missing callback', cCallback));
    return arrayMacro(dependentKey, additionalDependentKeys, function (value) {
      // This is so dumb...
      return Array.isArray(value) ? value.map(cCallback, this) : value.map(cCallback, this);
    });
  }
  /**
    Returns an array mapped to the specified key.
  
    Example:
  
    ```javascript
    import { set } from '@ember/object';
    import { mapBy } from '@ember/object/computed';
  
    class Person {
      children = [];
  
      @mapBy('children', 'age') childAges;
    }
  
    let lordByron = new Person();
  
    lordByron.childAges; // []
  
    set(lordByron, 'children', [
      {
        name: 'Augusta Ada Byron',
        age: 7
      }
    ]);
    lordByron.childAges; // [7]
  
    set(lordByron, 'children', [
      ...lordByron.children,
      {
        name: 'Allegra Byron',
        age: 5
      }, {
        name: 'Elizabeth Medora Leigh',
        age: 8
      }
    ]);
    lordByron.childAges; // [7, 5, 8]
    ```
  
    @method mapBy
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @param {String} propertyKey
    @return {ComputedProperty} an array mapped to the specified key
    @public
  */

  function mapBy(dependentKey, propertyKey) {
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @mapBy as a decorator directly, but it requires `dependentKey` and `propertyKey` parameters', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    (true && !(typeof propertyKey === 'string') && (0, _debug.assert)('`mapBy` computed macro expects a property string for its second argument, ' + 'perhaps you meant to use "map"', typeof propertyKey === 'string'));
    (true && !(!/[[\]{}]/g.test(dependentKey)) && (0, _debug.assert)("Dependent key passed to `mapBy` computed macro shouldn't contain brace expanding pattern.", !/[[\]{}]/g.test(dependentKey)));
    return map(dependentKey + ".@each." + propertyKey, function (item) {
      return (0, _metal.get)(item, propertyKey);
    });
  }
  function filter(dependentKey, additionalDependentKeysOrCallback, callback) {
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @filter as a decorator directly, but it requires atleast `dependentKey` and `callback` parameters', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    (true && !(typeof callback === 'function' || callback === undefined && typeof additionalDependentKeysOrCallback === 'function') && (0, _debug.assert)('The final parameter provided to filter must be a callback function', typeof callback === 'function' || callback === undefined && typeof additionalDependentKeysOrCallback === 'function'));
    (true && !(Array.isArray(additionalDependentKeysOrCallback) || typeof additionalDependentKeysOrCallback === 'function') && (0, _debug.assert)('The second parameter provided to filter must either be the callback or an array of additional dependent keys', Array.isArray(additionalDependentKeysOrCallback) || typeof additionalDependentKeysOrCallback === 'function'));
    var additionalDependentKeys;
    if (typeof additionalDependentKeysOrCallback === 'function') {
      callback = additionalDependentKeysOrCallback;
      additionalDependentKeys = [];
    } else {
      additionalDependentKeys = additionalDependentKeysOrCallback;
    }
    var cCallback = callback;
    return arrayMacro(dependentKey, additionalDependentKeys, function (value) {
      // This is a really silly way to keep TS happy
      return Array.isArray(value) ? value.filter(cCallback, this) : value.filter(cCallback, this);
    });
  }
  /**
    Filters the array by the property and value.
  
    Example:
  
    ```javascript
    import { set } from '@ember/object';
    import { filterBy } from '@ember/object/computed';
  
    class Hamster {
      constructor(chores) {
        set(this, 'chores', chores);
      }
  
      @filterBy('chores', 'done', false) remainingChores;
    }
  
    let hamster = new Hamster([
      { name: 'cook', done: true },
      { name: 'clean', done: true },
      { name: 'write more unit tests', done: false }
    ]);
  
    hamster.remainingChores; // [{ name: 'write more unit tests', done: false }]
    ```
  
    @method filterBy
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @param {String} propertyKey
    @param {*} value
    @return {ComputedProperty} the filtered array
    @public
  */

  function filterBy(dependentKey, propertyKey, value) {
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @filterBy as a decorator directly, but it requires atleast `dependentKey` and `propertyKey` parameters', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    (true && !(!/[[\]{}]/g.test(dependentKey)) && (0, _debug.assert)("Dependent key passed to `filterBy` computed macro shouldn't contain brace expanding pattern.", !/[[\]{}]/g.test(dependentKey)));
    var callback;
    if (arguments.length === 2) {
      callback = function callback(item) {
        return (0, _metal.get)(item, propertyKey);
      };
    } else {
      callback = function callback(item) {
        return (0, _metal.get)(item, propertyKey) === value;
      };
    }
    return filter(dependentKey + ".@each." + propertyKey, callback);
  }
  /**
    A computed property which returns a new array with all the unique elements
    from one or more dependent arrays.
  
    Example:
  
    ```javascript
    import { set } from '@ember/object';
    import { uniq } from '@ember/object/computed';
  
    class Hamster {
      constructor(fruits) {
        set(this, 'fruits', fruits);
      }
  
      @uniq('fruits') uniqueFruits;
    }
  
    let hamster = new Hamster([
      'banana',
      'grape',
      'kale',
      'banana'
    ]);
  
    hamster.uniqueFruits; // ['banana', 'grape', 'kale']
    ```
  
    @method uniq
    @for @ember/object/computed
    @static
    @param {String} propertyKey*
    @return {ComputedProperty} computes a new array with all the
    unique elements from the dependent array
    @public
  */

  function uniq(dependentKey) {
    for (var _len = arguments.length, additionalDependentKeys = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      additionalDependentKeys[_key - 1] = arguments[_key];
    }
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @uniq/@union as a decorator directly, but it requires atleast one dependent key parameter', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    var args = [dependentKey].concat(additionalDependentKeys);
    return multiArrayMacro(args, function (dependentKeys) {
      var _this = this;
      var uniq = (0, _runtime.A)();
      var seen = new Set();
      dependentKeys.forEach(function (dependentKey) {
        var value = (0, _metal.get)(_this, dependentKey);
        if (isNativeOrEmberArray(value)) {
          value.forEach(function (item) {
            if (!seen.has(item)) {
              seen.add(item);
              uniq.push(item);
            }
          });
        }
      });
      return uniq;
    }, 'uniq');
  }
  /**
    A computed property which returns a new array with all the unique elements
    from an array, with uniqueness determined by specific key.
  
    Example:
  
    ```javascript
    import { set } from '@ember/object';
    import { uniqBy } from '@ember/object/computed';
  
    class Hamster {
      constructor(fruits) {
        set(this, 'fruits', fruits);
      }
  
      @uniqBy('fruits', 'id') uniqueFruits;
    }
  
    let hamster = new Hamster([
      { id: 1, 'banana' },
      { id: 2, 'grape' },
      { id: 3, 'peach' },
      { id: 1, 'banana' }
    ]);
  
    hamster.uniqueFruits; // [ { id: 1, 'banana' }, { id: 2, 'grape' }, { id: 3, 'peach' }]
    ```
  
    @method uniqBy
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @param {String} propertyKey
    @return {ComputedProperty} computes a new array with all the
    unique elements from the dependent array
    @public
  */

  function uniqBy(dependentKey, propertyKey) {
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @uniqBy as a decorator directly, but it requires `dependentKey` and `propertyKey` parameters', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    (true && !(!/[[\]{}]/g.test(dependentKey)) && (0, _debug.assert)("Dependent key passed to `uniqBy` computed macro shouldn't contain brace expanding pattern.", !/[[\]{}]/g.test(dependentKey)));
    return (0, _metal.computed)(dependentKey + ".[]", function () {
      var list = (0, _metal.get)(this, dependentKey);
      return isNativeOrEmberArray(list) ? (0, _runtime.uniqBy)(list, propertyKey) : (0, _runtime.A)();
    }).readOnly();
  }
  /**
    A computed property which returns a new array with all the unique elements
    from one or more dependent arrays.
  
    Example:
  
    ```javascript
    import { set } from '@ember/object';
    import { union } from '@ember/object/computed';
  
    class Hamster {
      constructor(fruits, vegetables) {
        set(this, 'fruits', fruits);
        set(this, 'vegetables', vegetables);
      }
  
      @union('fruits', 'vegetables') uniqueFruits;
    });
  
    let hamster = new, Hamster(
      [
        'banana',
        'grape',
        'kale',
        'banana',
        'tomato'
      ],
      [
        'tomato',
        'carrot',
        'lettuce'
      ]
    );
  
    hamster.uniqueFruits; // ['banana', 'grape', 'kale', 'tomato', 'carrot', 'lettuce']
    ```
  
    @method union
    @for @ember/object/computed
    @static
    @param {String} propertyKey*
    @return {ComputedProperty} computes a new array with all the unique elements
    from one or more dependent arrays.
    @public
  */

  var union = uniq;
  /**
    A computed property which returns a new array with all the elements
    two or more dependent arrays have in common.
  
    Example:
  
    ```javascript
    import { set } from '@ember/object';
    import { intersect } from '@ember/object/computed';
  
    class FriendGroups {
      constructor(adaFriends, charlesFriends) {
        set(this, 'adaFriends', adaFriends);
        set(this, 'charlesFriends', charlesFriends);
      }
  
      @intersect('adaFriends', 'charlesFriends') friendsInCommon;
    }
  
    let groups = new FriendGroups(
      ['Charles Babbage', 'John Hobhouse', 'William King', 'Mary Somerville'],
      ['William King', 'Mary Somerville', 'Ada Lovelace', 'George Peacock']
    );
  
    groups.friendsInCommon; // ['William King', 'Mary Somerville']
    ```
  
    @method intersect
    @for @ember/object/computed
    @static
    @param {String} propertyKey*
    @return {ComputedProperty} computes a new array with all the duplicated
    elements from the dependent arrays
    @public
  */
  _exports.union = union;
  function intersect(dependentKey) {
    for (var _len2 = arguments.length, additionalDependentKeys = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      additionalDependentKeys[_key2 - 1] = arguments[_key2];
    }
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @intersect as a decorator directly, but it requires atleast one dependent key parameter', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    var args = [dependentKey].concat(additionalDependentKeys);
    return multiArrayMacro(args, function (dependentKeys) {
      var _this2 = this;
      var arrays = dependentKeys.map(function (dependentKey) {
        var array = (0, _metal.get)(_this2, dependentKey);
        return Array.isArray(array) ? array : [];
      });
      var firstArray = arrays.pop();
      (true && !(firstArray) && (0, _debug.assert)('Attempted to apply multiArrayMacro for intersect without any dependentKeys', firstArray));
      var results = firstArray.filter(function (candidate) {
        for (var _iterator = (0, _emberBabel.createForOfIteratorHelperLoose)(arrays), _step; !(_step = _iterator()).done;) {
          var _array = _step.value;
          var _found = false;
          for (var _iterator2 = (0, _emberBabel.createForOfIteratorHelperLoose)(_array), _step2; !(_step2 = _iterator2()).done;) {
            var _item = _step2.value;
            if (_item === candidate) {
              _found = true;
              break;
            }
          }
          if (_found === false) {
            return false;
          }
        }
        return true;
      });
      return (0, _runtime.A)(results);
    }, 'intersect');
  }
  /**
    A computed property which returns a new array with all the properties from the
    first dependent array that are not in the second dependent array.
  
    Example:
  
    ```javascript
    import { set } from '@ember/object';
    import { setDiff } from '@ember/object/computed';
  
    class Hamster {
      constructor(likes, fruits) {
        set(this, 'likes', likes);
        set(this, 'fruits', fruits);
      }
  
      @setDiff('likes', 'fruits') wants;
    }
  
    let hamster = new Hamster(
      [
        'banana',
        'grape',
        'kale'
      ],
      [
        'grape',
        'kale',
      ]
    );
  
    hamster.wants; // ['banana']
    ```
  
    @method setDiff
    @for @ember/object/computed
    @static
    @param {String} setAProperty
    @param {String} setBProperty
    @return {ComputedProperty} computes a new array with all the items from the
    first dependent array that are not in the second dependent array
    @public
  */

  function setDiff(setAProperty, setBProperty) {
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @setDiff as a decorator directly, but it requires atleast one dependent key parameter', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    (true && !(arguments.length === 2) && (0, _debug.assert)('`setDiff` computed macro requires exactly two dependent arrays.', arguments.length === 2));
    (true && !(!/[[\]{}]/g.test(setAProperty) && !/[[\]{}]/g.test(setBProperty)) && (0, _debug.assert)("Dependent keys passed to `setDiff` computed macro shouldn't contain brace expanding pattern.", !/[[\]{}]/g.test(setAProperty) && !/[[\]{}]/g.test(setBProperty)));
    return (0, _metal.computed)(setAProperty + ".[]", setBProperty + ".[]", function () {
      var setA = (0, _metal.get)(this, setAProperty);
      var setB = (0, _metal.get)(this, setBProperty);
      if (!isNativeOrEmberArray(setA)) {
        return (0, _runtime.A)();
      }
      if (!isNativeOrEmberArray(setB)) {
        return setA;
      }
      return setA.filter(function (x) {
        return setB.indexOf(x) === -1;
      });
    }).readOnly();
  }
  /**
    A computed property that returns the array of values for the provided
    dependent properties.
  
    Example:
  
    ```javascript
    import { set } from '@ember/object';
    import { collect } from '@ember/object/computed';
  
    class Hamster {
      @collect('hat', 'shirt') clothes;
    }
  
    let hamster = new Hamster();
  
    hamster.clothes; // [null, null]
  
    set(hamster, 'hat', 'Camp Hat');
    set(hamster, 'shirt', 'Camp Shirt');
    hamster.clothes; // ['Camp Hat', 'Camp Shirt']
    ```
  
    @method collect
    @for @ember/object/computed
    @static
    @param {String} dependentKey*
    @return {ComputedProperty} computed property which maps values of all passed
    in properties to an array.
    @public
  */

  function collect(dependentKey) {
    for (var _len3 = arguments.length, additionalDependentKeys = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
      additionalDependentKeys[_key3 - 1] = arguments[_key3];
    }
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @collect as a decorator directly, but it requires atleast one dependent key parameter', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    var dependentKeys = [dependentKey].concat(additionalDependentKeys);
    return multiArrayMacro(dependentKeys, function () {
      var _this3 = this;
      var res = dependentKeys.map(function (key) {
        var val = (0, _metal.get)(_this3, key);
        return val === undefined ? null : val;
      });
      return (0, _runtime.A)(res);
    }, 'collect');
  }
  function sort(itemsKey, additionalDependentKeysOrDefinition, sortDefinition) {
    (true && !(!(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))) && (0, _debug.assert)('You attempted to use @sort as a decorator directly, but it requires atleast an `itemsKey` parameter', !(0, _metal.isElementDescriptor)(Array.prototype.slice.call(arguments))));
    if (true /* DEBUG */) {
      var argumentsValid = false;
      if (arguments.length === 2) {
        argumentsValid = typeof itemsKey === 'string' && (typeof additionalDependentKeysOrDefinition === 'string' || typeof additionalDependentKeysOrDefinition === 'function');
      }
      if (arguments.length === 3) {
        argumentsValid = typeof itemsKey === 'string' && Array.isArray(additionalDependentKeysOrDefinition) && typeof sortDefinition === 'function';
      }
      (true && !(argumentsValid) && (0, _debug.assert)('The `sort` computed macro can either be used with an array of sort properties or with a sort function. If used with an array of sort properties, it must receive exactly two arguments: the key of the array to sort, and the key of the array of sort properties. If used with a sort function, it may receive up to three arguments: the key of the array to sort, an optional additional array of dependent keys for the computed property, and the sort function.', argumentsValid));
    }
    var additionalDependentKeys;
    var sortDefinitionOrString;
    if (Array.isArray(additionalDependentKeysOrDefinition)) {
      additionalDependentKeys = additionalDependentKeysOrDefinition;
      sortDefinitionOrString = sortDefinition;
    } else {
      additionalDependentKeys = [];
      sortDefinitionOrString = additionalDependentKeysOrDefinition;
    }
    if (typeof sortDefinitionOrString === 'function') {
      return customSort(itemsKey, additionalDependentKeys, sortDefinitionOrString);
    } else {
      return propertySort(itemsKey, sortDefinitionOrString);
    }
  }
  function customSort(itemsKey, additionalDependentKeys, comparator) {
    return arrayMacro(itemsKey, additionalDependentKeys, function (value) {
      var _this4 = this;
      return value.slice().sort(function (x, y) {
        return comparator.call(_this4, x, y);
      });
    });
  } // This one needs to dynamically set up and tear down observers on the itemsKey
  // depending on the sortProperties

  function propertySort(itemsKey, sortPropertiesKey) {
    var cp = (0, _metal.autoComputed)(function (key) {
      var sortProperties = (0, _metal.get)(this, sortPropertiesKey);
      (true && !(function (arr) {
        return isNativeOrEmberArray(arr) && arr.every(function (s) {
          return typeof s === 'string';
        });
      }(sortProperties)) && (0, _debug.assert)("The sort definition for '" + key + "' on " + this + " must be a function or an array of strings", function (arr) {
        return isNativeOrEmberArray(arr) && arr.every(function (s) {
          return typeof s === 'string';
        });
      }(sortProperties)));
      var itemsKeyIsAtThis = itemsKey === '@this';
      var normalizedSortProperties = normalizeSortProperties(sortProperties);
      var items = itemsKeyIsAtThis ? this : (0, _metal.get)(this, itemsKey);
      if (!isNativeOrEmberArray(items)) {
        return (0, _runtime.A)();
      }
      if (normalizedSortProperties.length === 0) {
        return (0, _runtime.A)(items.slice());
      } else {
        return sortByNormalizedSortProperties(items, normalizedSortProperties);
      }
    }).readOnly();
    return cp;
  }
  function normalizeSortProperties(sortProperties) {
    var callback = function callback(p) {
      var _p$split = p.split(':'),
        prop = _p$split[0],
        direction = _p$split[1];
      direction = direction || 'asc'; // SAFETY: There will always be at least one value returned by split

      return [prop, direction];
    }; // This nonsense is necessary since technically the two map implementations diverge.

    return Array.isArray(sortProperties) ? sortProperties.map(callback) : sortProperties.map(callback);
  }
  function sortByNormalizedSortProperties(items, normalizedSortProperties) {
    return (0, _runtime.A)(items.slice().sort(function (itemA, itemB) {
      for (var _iterator3 = (0, _emberBabel.createForOfIteratorHelperLoose)(normalizedSortProperties), _step3; !(_step3 = _iterator3()).done;) {
        var _step3$value = _step3.value,
          _prop = _step3$value[0],
          _direction = _step3$value[1];
        var _result = (0, _runtime.compare)((0, _metal.get)(itemA, _prop), (0, _metal.get)(itemB, _prop));
        if (_result !== 0) {
          return _direction === 'desc' ? -1 * _result : _result;
        }
      }
      return 0;
    }));
  }
});