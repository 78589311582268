define("ember-validators/inclusion", ["exports", "@ember/debug", "@ember/utils", "ember-validators/utils/validation-error"], function (_exports, _debug, _utils, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateInclusion;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) { n[e] = r[e]; } return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0) { ; } } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  /**
   *  @class Inclusion
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.allowBlank If true, skips validation if the value is empty
   * @param {Array} options.in The list of values this attribute could be
   * @param {Array} options.range The range in which the attribute's value should reside in
   * @param {Object} model
   * @param {String} attribute
   */
  function validateInclusion(value, options, model, attribute) {
    var array = options.in;
    var range = options.range,
      allowBlank = options.allowBlank;
    (true && !(!(0, _utils.isEmpty)(Object.keys(options))) && (0, _debug.assert)("[validator:inclusion] [".concat(attribute, "] no options were passed in"), !(0, _utils.isEmpty)(Object.keys(options))));
    if (allowBlank && (0, _utils.isEmpty)(value)) {
      return true;
    }
    if (array && array.indexOf(value) === -1) {
      return (0, _validationError.default)('inclusion', value, options);
    }
    if (range && range.length === 2) {
      var _range = _slicedToArray(range, 2),
        min = _range[0],
        max = _range[1];
      var equalType = (0, _utils.typeOf)(value) === (0, _utils.typeOf)(min) && (0, _utils.typeOf)(value) === (0, _utils.typeOf)(max);
      var isInvalidNumber = (0, _utils.typeOf)(value) === 'number' && isNaN(value);
      if (!equalType || isInvalidNumber || min > value || value > max) {
        return (0, _validationError.default)('inclusion', value, options);
      }
    }
    return true;
  }
});