define("ember-clock/services/clock", ["exports", "@ember/object/computed", "@ember/runloop", "@ember/service"], function (_exports, _computed, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    @module ember-clock
  */
  /**
    ## ClockService
  
    The clock service is injected into all controllers and components.  The clock
    synchronizes to the local host's system clock and can be used to display the
    time or to update time sensitive properties.
  
    To use the clock in a template or in computed properties, bind to the clock's
    `hour`, `minute`, or `second` properties.
  
    In templates:
  
    ```
    {{clock.hour}}
    {{clock.minute}}
    {{clock.second}}
    ```
  
    In computed properties:
  
    ```
    property: Ember.computed('clock.second', function () {
      // this will update every second
    })
    ```
  
    @class ClockService
    @namespace EmberClock
  */
  var _default = _service.default.extend({
    /**
      @property hour
      @type {Integer}
    */
    hour: null,
    /**
      @property minute
      @type {Integer}
    */
    minute: null,
    /**
     @property second
     @type {Integer}
    */
    second: null,
    /**
      Stores the next tick, so that it can be cancelled and the clock stopped.
      @property nextTick
      @type {Object}
      @private
    */
    nextTick: null,
    /**
      @property isTicking
      @type {Boolean}
      @readonly
      @private
    */
    isTicking: (0, _computed.bool)('nextTick'),
    /**
      Call `start()`
      @method init
      @private
    */
    init: function init() {
      this._super.apply(this, arguments);
      this.start();
    },
    /**
      Start the clock
      @method start
      @private
    */
    start: function start() {
      this.tick();
    },
    /**
      Stop the clock
      @method stop
      @private
    */
    stop: function stop() {
      _runloop.run.cancel(this.get('nextTick'));
      this.set('nextTick', null);
    },
    /**
      Set the time to the current time.
      @method setTime
      @private
    */
    setTime: function setTime() {
      var now = new Date();
      this.setProperties({
        second: now.getSeconds(),
        minute: now.getMinutes(),
        hour: now.getHours()
      });
    },
    /**
      Ticks the clock
      @method tick
      @private
    */
    tick: function tick() {
      this.setTime();
      if (this.get('disabled')) {
        return;
      }
      this.set('nextTick', _runloop.run.later(this, this.tick, 1000));
    },
    /**
      call `stop()`
      @event willDestroy
      @private
    */
    willDestroy: function willDestroy() {
      this.stop();
    }
  });
  _exports.default = _default;
});